import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppapiService } from '../service/appapi.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {
  foo;
  bar;
idb
  montant=0
  constructor(private api:AppapiService,private modal:ModalController) { }

  ngOnInit() {
    console.log(`${this.foo} ${this.bar} ${this.idb}`)
  }
  paye(){
    if(this.montant>=this.bar*1){
      this.api.payercredit(this.foo,this.montant-this.bar*1,0,this.idb,this.montant).subscribe(res=>{
        console.log(res)
        this.modal.dismiss()
      })
    }else{
      this.api.payercredit(this.foo,this.bar*1-this.montant,1,this.idb,this.montant).subscribe(res=>{
        console.log(res)
        this.modal.dismiss()
      })
    }
  }
}
