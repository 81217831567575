import { Component, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { AppapiService } from '../service/appapi.service';
@Component({
  selector: 'app-modal2',
  templateUrl: './modal2.page.html',
  styleUrls: ['./modal2.page.scss'],
})
export class Modal2Page implements OnInit {
  foo;
  bar;
 idb
  montant=0
  constructor(private api:AppapiService,private modal:ModalController) { }

  ngOnInit() {
    console.log(`${this.foo} ${this.bar} ${this.idb}`)
  }
  paye(){
 
      this.api.augmentercredit(this.idb,this.foo,this.bar*1+this.montant*1,this.montant).subscribe(res=>{
        console.log(res)
        this.modal.dismiss()
      })
    
  }



}
