import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppapiService } from '../service/appapi.service';

@Component({
  selector: 'app-modaledit',
  templateUrl: './modaledit.page.html',
  styleUrls: ['./modaledit.page.scss'],
})
export class ModaleditPage implements OnInit {
  idb
  idp
  achat
  vente
  devise
  produit
  constructor(private modal:ModalController,private api:AppapiService) { }

  ngOnInit() {
   // console.log(`${this.idb} ${this.idp} ${this.idb}`)
  }
modifier(){
  let data= new FormData()
  data.append("produit",this.produit)
  data.append("achat",this.achat)
  data.append("vente",this.vente)
  data.append("devise",this.devise)
  data.append("id",this.idp)
this.api.upadte(data).subscribe(res=>{
  this.modal.dismiss()
})
  console.log(this.produit)
}
}
